import { gql } from "@apollo/client";
import produce from "immer";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "../../contexts/auth";
import { Result, queryKey } from "./useFetchApplicationMe";
import { ApplicantEmploymentNode } from "./useFetchApplicationMe/Applicant";

export function useUpdateEmployment() {
  const { gqlClient } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(async (data: Partial<ApplicantEmploymentNode>) => {
    queryClient.setQueryData(
      queryKey,
      produce(queryClient.getQueryData(queryKey), (draft: Result) => {
        draft.applicantById.employments.edges.forEach((edge) => {
          if (edge.node.applicantEmploymentId === data.applicantEmploymentId) {
            edge.node = {
              ...edge.node,
              ...data,
            };
          }
        });
      })
    );

    await gqlClient.mutate({
      mutation: gql`
        mutation ($applicantEmploymentUpdateData: ApplicantEmploymentUpdateData) {
          updateApplicantEmployment(applicantEmployment: $applicantEmploymentUpdateData) {
            count
          }
        }
      `,
      variables: {
        applicantEmploymentUpdateData: {
          applicantEmploymentId: data.applicantEmploymentId,
          isCurrent: data.isCurrent,
          employer: data.employer,
          jobTitle: data.jobTitle,
          streetAddress: data.streetAddress,
          unitNumber: data.unitNumber,
          state: data.state,
          country: data.country,
          city: data.city,
          zipcode: data.zipcode,
          annualIncomeInCents: data.annualIncomeInCents,
        },
      },
    });
  });
}
