import { Status, TextField, Props as TextFieldProps } from "@doorstead/components/fields/TextField";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";

export type Props = Omit<TextFieldProps, "value" | "onChange"> & {
  defaultValue: string | null;
  transform?: (value: string) => string;
  getStatus?: (value: string) => Status;
  onSyncToServer: (value: string) => void;
  debounce?: number;
};

export function ApplicantTextField({
  id,
  defaultValue,
  onSyncToServer,
  transform = (e) => e,
  getStatus,
  debounce = 1500,
  ...textProps
}: Props) {
  const debouncedSyncToServer = useCallback(_.debounce(onSyncToServer, debounce), [onSyncToServer]);
  const [value, setValue] = useState<string>(transform(defaultValue || ""));

  useEffect(() => {
    return () => {
      debouncedSyncToServer.cancel();
    };
  }, [id]);

  return (
    <TextField
      {...Object.assign(textProps, getStatus ? { status: getStatus(value) } : {})}
      id={id}
      value={value}
      onChange={(e) => {
        const value = transform(e.target.value);
        setValue(value);
        debouncedSyncToServer(value);
      }}
    />
  );
}
