import { VStack } from "@chakra-ui/react";
import { SelectField } from "@doorstead/components";
import { SwitchField, YesNoSelectField } from "@doorstead/components/fields";
import { Status } from "@doorstead/components/fields/TextField";
import { useState } from "react";
import { ApplicantAnimalNode } from "../../../../../../hooks/applicants/useFetchApplicationMe/Applicant";
import { useIsApplicantEditable } from "../../../../../../hooks/applicants/useIsApplicantEditable";
import { getTextRequiredStatus } from "../../../../../../utils/getTextRequiredStatus";
import { ApplicantDollarField } from "../../../../ApplicantDollarField";
import { ApplicantTextField } from "../../../../ApplicantTextField";

export type Props = {
  value: ApplicantAnimalNode;
  onChange: (
    animal: Partial<ApplicantAnimalNode> & { applicantAnimalId: ApplicantAnimalNode["applicantAnimalId"] }
  ) => void;
};

export function Content({
  value: { applicantAnimalId, name, isServiceAnimal, color, weightLb, type, breed, hasVerification },
  onChange,
}: Props) {
  const disabled = !useIsApplicantEditable();
  const [selectedValue, setValue] = useState(type);

  return (
    <VStack width="100%">
      <SelectField
        id="type"
        label="Type"
        value={selectedValue}
        isError={selectedValue === ""}
        options={[
          {
            value: "Dog",
            name: "Dog",
          },
          {
            value: "Cat",
            name: "Cat",
          },
          {
            value: "Other",
            name: "Other",
          },
        ]}
        onChange={({ target: { value: selectedValue } }) => {
          onChange({
            applicantAnimalId,
            type: selectedValue,
          });
          setValue(selectedValue); // set the value of SelectField to the selected value
        }}
      />
      <ApplicantTextField
        id="name"
        label="Name"
        disabled={disabled}
        defaultValue={name}
        onSyncToServer={(name) =>
          onChange({
            applicantAnimalId,
            name,
          })
        }
      />
      <ApplicantTextField
        id="breed"
        label="Breed"
        disabled={disabled}
        defaultValue={breed}
        getStatus={(value) => {
          if (type === "Dog") {
            return getTextRequiredStatus(value);
          }
          return Status.Default;
        }}
        onSyncToServer={(breed) =>
          onChange({
            applicantAnimalId,
            breed,
          })
        }
      />
      <ApplicantTextField
        id="color"
        label="Color"
        disabled={disabled}
        defaultValue={color}
        onSyncToServer={(color) =>
          onChange({
            applicantAnimalId,
            color,
          })
        }
      />
      <ApplicantDollarField
        id="weight"
        label="Weight (lb)"
        disabled={disabled}
        defaultValue={weightLb}
        min={0}
        max={9999999}
        hasDollarSign={false}
        getIsValid={(v) => {
          if (type === "Dog") {
            return v > 0;
          }
          return true;
        }}
        onSyncToServer={(weightLb) =>
          onChange({
            applicantAnimalId,
            weightLb,
          })
        }
      />
      <SwitchField
        value={!!isServiceAnimal}
        disabled={disabled}
        label="Service animal or ESA"
        onChange={(isServiceAnimal) => {
          onChange({
            applicantAnimalId,
            isServiceAnimal,
          });
        }}
      />
      <YesNoSelectField
        id="hasVerification"
        disabled={disabled}
        label="Are you able to provide verification if asked?"
        value={hasVerification}
        onChange={(hasVerification) => {
          onChange({
            applicantAnimalId,
            hasVerification,
          });
        }}
      />
    </VStack>
  );
}
