import { Box, VStack } from "@chakra-ui/react";
import produce from "immer";
import { useAddAnimal } from "../../../../../hooks/applicants/useAddAnimal";
import { useAddOccupant } from "../../../../../hooks/applicants/useAddOccupant";
import { useApplicantPageView } from "../../../../../hooks/applicants/useApplicantPageView";
import { useClearAnimals } from "../../../../../hooks/applicants/useClearAnimals";
import { useClearOccupants } from "../../../../../hooks/applicants/useClearOccupants";
import { ApplicantOnboardingStage } from "../../../../../hooks/applicants/useFetchApplicationMe/Applicant";
import { useFetchedApplicant } from "../../../../../hooks/applicants/useFetchedApplicant";
import { useFetchedApplicationMe } from "../../../../../hooks/applicants/useFetchedApplicationMe";
import { useIsAdditionalOccupantsFeature } from "../../../../../hooks/applicants/useIsAdditionalOccupantsFeature";
import { useIsPrimaryApplicant } from "../../../../../hooks/applicants/useIsPrimaryApplicant";
import { useRemoveAnimal } from "../../../../../hooks/applicants/useRemoveAnimal";
import { useRemoveOccupant } from "../../../../../hooks/applicants/useRemoveOccupant";
import { useUpdateAnimal } from "../../../../../hooks/applicants/useUpdateAnimal";
import { useUpdateApplicantOnboardingStage } from "../../../../../hooks/applicants/useUpdateApplicantOnboardingStage";
import { useUpdateOccupant } from "../../../../../hooks/applicants/useUpdateOccupant";
import { useScrollIntoView } from "../../../../../hooks/useScrollIntoView";
import { isNullOrEmptyString } from "../../../../../utils/isNullOrEmptyString";
import { maxWidth, padding, width } from "../../config";
import { CurrentAddressCard } from "./CurrentResidenceCard";
import { NameAndContactInformationCard } from "./NameAndContactInformationCard";
import { OccupantsCard } from "./OccupantsCard";
import { PetsCard } from "./PetsCard";
import { PreviousResidenceCard } from "./PreviousResidenceCard";
import { getPetsError } from "./getPetsError";

export function YourInformationSection() {
  const isAdditionalOccupantsFeature = useIsAdditionalOccupantsFeature();
  const applicantAnalytics = useApplicantPageView("TP_YOUR_INFO_SECTION_VIEWED");
  const {
    applicantById: { doorsteadUser, animals, occupants },
  } = useFetchedApplicationMe();
  const applicant = useFetchedApplicant();
  const scrollIntoView = useScrollIntoView();
  const { mutate: updateAnimal } = useUpdateAnimal();
  const { mutate: addAnimal } = useAddAnimal();
  const { mutate: addOccupant } = useAddOccupant();
  const { mutate: updateOccupant } = useUpdateOccupant();
  const { mutate: removeAnimal } = useRemoveAnimal();
  const { mutate: removeOccupant } = useRemoveOccupant();
  const { mutate: clearAnimals } = useClearAnimals();
  const { mutate: clearOccupants } = useClearOccupants();
  const { mutate: updateOnboardingStage } = useUpdateApplicantOnboardingStage();
  const isPrimaryApplicant = useIsPrimaryApplicant();

  if (applicant === null) {
    return <Box>Sorry, we can not find this applicant</Box>;
  }

  const PetBox = ({ id }: { id: string }) => {
    return (
      <PetsCard
        idField="applicantAnimalId"
        id={id}
        width={width}
        maxWidth={maxWidth}
        padding={padding}
        subTitle={
          isAdditionalOccupantsFeature ? (
            <Box>
              {`Add any and all pets who will live on the property, including those associated with any co-applicants. Please ensure pet information is complete and accurate. Owners and/or HOA's can have strict pet rules, and failure to provide this information could cause your application to be disqualified at a later stage. Please fill out on behalf of all tenants.`}
            </Box>
          ) : (
            <Box>
              <Box as="span" fontWeight="bold" textAlign="left" mr={1}>
                Please ensure pet information is complete and accurate.
              </Box>
              {`Owners and/or HOA’s can have strict pet rules, and failure to provide this information could cause your application to be disqualified at a later stage`}
            </Box>
          )
        }
        values={animals.edges.map(({ node }) => node)}
        onAdd={addAnimal}
        onReset={() => clearAnimals()}
        onRemove={removeAnimal}
        onChange={(animal) => {
          updateAnimal({
            ...animal,
          });
        }}
        onContinue={async () => {
          applicantAnalytics?.track("TP_PETS_CARD_CONTINUED");

          if (isAdditionalOccupantsFeature) {
            // occupants validation
            const hasEmptyOccupantFullName = occupants.edges
              .map(({ node }) => node)
              .some(({ fullName }) => isNullOrEmptyString(fullName));

            if (hasEmptyOccupantFullName) {
              scrollIntoView("/0/children/3");
            } else {
              if (getPetsError(animals).hasError) {
                scrollIntoView("/0/children/4");
              } else {
                updateOnboardingStage(ApplicantOnboardingStage.DOCUMENTS_SECTION);
              }
            }
          } else {
            updateOnboardingStage(ApplicantOnboardingStage.DOCUMENTS_SECTION);
          }
        }}
      />
    );
  };

  return (
    <Box id="/0">
      <VStack spacing={5}>
        <NameAndContactInformationCard
          id="/0/children/0"
          width={width}
          maxWidth={maxWidth}
          padding={padding}
          initialValue={{
            firstName: doorsteadUser.firstName || "",
            middleName: doorsteadUser.middleName || "",
            lastName: doorsteadUser.lastName || "",
            suffixName: doorsteadUser.suffixName || "",
            phone: doorsteadUser.phone || "",
            email: doorsteadUser.email || "",
          }}
          onContinue={() => {
            applicantAnalytics?.track("TP_NAME_CONTACT_CARD_CONTINUED");
            scrollIntoView("/0/children/1");
          }}
        />
        <CurrentAddressCard
          id="/0/children/1"
          width={width}
          maxWidth={maxWidth}
          padding={padding}
          onContinue={() => {
            applicantAnalytics?.track("TP_CURRENT_ADDRESS_CARD_CONTINUED");
            scrollIntoView("/0/children/2");
          }}
        />
        <PreviousResidenceCard
          id="/0/children/2"
          width={width}
          maxWidth={maxWidth}
          padding={padding}
          onContinue={async () => {
            applicantAnalytics?.track("TP_PREVIOUS_RESIDENCE_CARD_CONTINUED");
            if (!isAdditionalOccupantsFeature) {
              scrollIntoView("/0/children/3");
            } else {
              if (isPrimaryApplicant) {
                scrollIntoView("/0/children/3");
              } else {
                // If the user is not the primary applicant, we skip the occupants section
                updateOnboardingStage(ApplicantOnboardingStage.DOCUMENTS_SECTION);
              }
            }
          }}
        />
        {!isAdditionalOccupantsFeature && <PetBox id="/0/children/3" />}
        {isAdditionalOccupantsFeature && isPrimaryApplicant && (
          <>
            <OccupantsCard
              idField="applicantOccupantId"
              id="/0/children/3"
              width={width}
              maxWidth={maxWidth}
              padding={padding}
              values={produce(
                occupants.edges.map(({ node }) => node),
                (draft) => {
                  return draft.sort((a, b) => {
                    //use createdAt to sort the applicants
                    if (a.createdAt && b.createdAt) {
                      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
                    }
                    return 0;
                  });
                }
              )}
              hasAddMoreButton={occupants.edges.length < 4}
              onAdd={addOccupant}
              onReset={() => clearOccupants()}
              onRemove={removeOccupant}
              onChange={(occupant) => {
                updateOccupant({
                  applicantOccupantId: occupant.applicantOccupantId,
                  fullName: occupant.fullName,
                });
              }}
              onContinue={async () => {
                applicantAnalytics?.track("TP_OCCUPANTS_CARD_CONTINUED");
                scrollIntoView("/0/children/4");
              }}
            />
            <PetBox id="/0/children/4" />
          </>
        )}
      </VStack>
    </Box>
  );
}
