import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import { OutlineButton } from "@doorstead/components";
import { moneyString } from "@doorstead/components";
import { Card } from "@doorstead/components/Card";
import { GoogleMapImage } from "@doorstead/components/GoogleMapImage";
import { GrayText } from "@doorstead/components/GrayText";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useIsNegotiated } from "src/hooks/applicants/useIsNegotiated";
import { useLeaseNegotiationEnabled } from "src/hooks/applicants/useLeaseNegotiationEnabled";
// import { convertDateBetweenTimeZones } from "src/components/applicants/ApplicantLeaseNegotiationStartDatePage/convertDateBetweenTimeZones";
import { getNegotiatedDateFormattedDateString } from "src/utils/getNegotiatedDateFormattedDateString";
import { useAddress } from "../../../../hooks/applicants/useAddress";
import { useFetchedApplicant } from "../../../../hooks/applicants/useFetchedApplicant";
import { useGoogleMapKey } from "../../../../hooks/useGoogleMapKey";
import { getSegmentedAddress } from "../../../../hooks/useSegmentedAddress";
import { padding, width } from "../config";

type Props = {
  action: React.ReactNode;
};

export function PropertyInfoCard({ action }: Props) {
  const isMobile = useIsMobile();
  const {
    applicantId,
    applicationRequest: {
      property,
      negotiatedMoveInDate,
      negotiatedRent,
      negotiationLinkEndAt,
      negotiationLinkStartAt,
    },
  } = useFetchedApplicant();
  const googleMapKey = useGoogleMapKey();
  const address = useAddress();
  const [seg1, seg2] = getSegmentedAddress(property);
  const Layout = isMobile ? VStack : HStack;
  const navigate = useNavigate();
  const isNegotiated = useIsNegotiated();
  const leaseNegotiationEnabled = useLeaseNegotiationEnabled();

  const currentDate = new Date();
  const negotiationLinkEndDate = negotiationLinkEndAt ? new Date(negotiationLinkEndAt) : null;
  const shouldShowNegotiationButton =
    negotiationLinkStartAt && negotiationLinkEndDate && negotiationLinkEndDate > currentDate;

  return (
    <Flex justifyContent="center" w="100%">
      <Card padding={padding} width={width} maxWidth="800px">
        <Layout w="100%" alignItems="flex-start">
          <Box w="fit-content" h="100%">
            <GoogleMapImage
              borderRadius={6}
              width={isMobile ? 322 : 240}
              height={isMobile ? 220 : 220}
              address={address}
              googleKey={googleMapKey}
            />
          </Box>
          <Flex padding={isMobile ? 0 : 5} w="100%" h="100%" alignItems="center">
            <VStack spacing={2} alignItems="flex-start" w="100%">
              <GrayText>Application for</GrayText>
              <VStack
                spacing={0}
                alignItems="flex-start"
                fontWeight={isMobile ? "normal" : "bold"}
                fontSize="3xl"
                color="gray.700"
              >
                <Box>{seg1}</Box>
                <Box>{seg2}</Box>
              </VStack>
              {leaseNegotiationEnabled && isNegotiated && (
                <HStack
                  w="100%"
                  justifyContent="space-between"
                  alignContent="space-between"
                  bgColor="#F1EEFB"
                  px={5}
                  py={3}
                  borderRadius={14}
                >
                  <HStack spacing={5}>
                    <VStack alignItems="flex-start" spacing={0}>
                      <Box fontSize={isMobile ? 13 : 15}>Lease start date:</Box>
                      <Box fontWeight="bold">{getNegotiatedDateFormattedDateString(negotiatedMoveInDate)}</Box>
                    </VStack>

                    <VStack alignItems="flex-start" spacing={0}>
                      <Box fontSize={isMobile ? 13 : 15}>Monthly rent:</Box>
                      <Box fontWeight="bold">{moneyString(negotiatedRent, false)}</Box>
                    </VStack>
                  </HStack>

                  {shouldShowNegotiationButton && (
                    <OutlineButton
                      size="md"
                      colorScheme="purple"
                      onClick={() => {
                        console.log("edit");
                        navigate(`/lsd?applicantId=${applicantId}`);
                      }}
                    >
                      edit
                    </OutlineButton>
                  )}
                </HStack>
              )}

              <Box pt={6} w="100%">
                {action}
              </Box>
            </VStack>
          </Flex>
        </Layout>
      </Card>
    </Flex>
  );
}
