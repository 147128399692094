import { Stack } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton } from "@doorstead/components/buttons";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { useNavigate } from "react-router-dom";
import { useApplicantId } from "src/hooks/applicants/useApplicantId";
import { useIsPrimaryApplicant } from "src/hooks/applicants/useIsPrimaryApplicant";
import { useApplicantPageView } from "../../../../hooks/applicants/useApplicantPageView";
import { PropertyInfoCard } from "../PropertyInfoCard";

type Props = {
  isLoading: boolean;
  onContinue: () => void;
};

export function ApplicantNewCard({ isLoading, onContinue }: Props) {
  const applicantAnalytics = useApplicantPageView("TP_APPLICANT_NEW_VIEWED");
  const isMobile = useIsMobile();
  const applicantId = useApplicantId();
  const isPrimaryApplicant = useIsPrimaryApplicant();
  const navigate = useNavigate();

  return (
    <PropertyInfoCard
      action={
        <Stack direction="row" spacing={0} gap={4} flexWrap="wrap" w="100%">
          <PrimaryButton
            isFullWidth={isMobile}
            isLoading={isLoading}
            onClick={() => {
              applicantAnalytics?.track("TP_START_APPLICATION_CLICKED");
              onContinue();
            }}
          >
            Start Application
          </PrimaryButton>
          {isPrimaryApplicant && (
            <OutlineButton
              onClick={() => {
                applicantAnalytics?.track("TP_INVITE_COTENANT_CLICKED");
                navigate(`/application/${applicantId}/invite`);
              }}
            >
              Invite Co-tenant
            </OutlineButton>
          )}
        </Stack>
      }
    />
  );
}
