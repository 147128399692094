import { Box } from "@chakra-ui/react";
import { ApplicantOccupantNode } from "../../../../../../hooks/applicants/useFetchApplicationMe/Applicant";
import { ValuesCard } from "../../ValuesCard";
import { Content } from "./Content";
import { Props } from "./typings";

export function OccupantsCard({ id, onChange, onRemove, hasAddMoreButton, ...valuesProps }: Props) {
  return (
    <ValuesCard
      {...valuesProps}
      id={id}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      idField="applicantOccupantId"
      eventName="Occupants"
      title="Occupants"
      subTitle={
        <Box>
          Add any and all additional occupants who will live on the property, including those associated with any
          co-applicants. An occupant is anyone living in the home who is either under 18 years old or not financially
          responsible for the lease agreement. Please fill out on behalf of all tenants.
        </Box>
      }
      noLabel="No occupants"
      yesLabel="Add occupants"
      numberLabel="Occupant"
      addLabel="Add more occupants"
      content={(_ignored, value) => <Content value={value as ApplicantOccupantNode} onChange={onChange} />}
      hasAddMoreButton={hasAddMoreButton}
      onRemove={(_ignored, value) => {
        onRemove((value as ApplicantOccupantNode).applicantOccupantId);
      }}
    />
  );
}
