import { Flex, HStack, VStack } from "@chakra-ui/react";
import { Checkbox } from "@doorstead/components";
import { StateSelectField } from "@doorstead/components/fields";
import { CountrySelectField } from "@doorstead/components/fields/CountrySelectField";
import { Status } from "@doorstead/components/fields/TextField";
import { ApplicantEmploymentNode } from "../../../../../../hooks/applicants/useFetchApplicationMe/Applicant";
import { useIsApplicantEditable } from "../../../../../../hooks/applicants/useIsApplicantEditable";
import { getCents } from "../../../../../../utils/getCents";
import { getDollarByCents } from "../../../../../../utils/getDollarByCents";
import { getTextRequiredStatus } from "../../../../../../utils/getTextRequiredStatus";
import { ApplicantDollarField } from "../../../../ApplicantDollarField";
import { ApplicantTextField } from "../../../../ApplicantTextField";
import { isZipCodeValid } from "../../YourInformationSection/ResidenceCard/isZipCodeValid";
import { replaceAllowedZipCode } from "../../YourInformationSection/ResidenceCard/replaceAllowedZipCode";

export function Content({
  value,
  onChange,
}: {
  value: ApplicantEmploymentNode;
  onChange: (
    employmentNode: Partial<ApplicantEmploymentNode> & {
      applicantEmploymentId: ApplicantEmploymentNode["applicantEmploymentId"];
    }
  ) => void;
}) {
  const disabled = !useIsApplicantEditable();

  return (
    <VStack width="100%" spacing={5}>
      <VStack width="100%">
        <ApplicantTextField
          id="employer"
          label="Employer"
          name="company"
          disabled={disabled}
          defaultValue={value.employer}
          getStatus={getTextRequiredStatus}
          onSyncToServer={(employer) =>
            onChange({
              applicantEmploymentId: value.applicantEmploymentId,
              employer,
            })
          }
        />
        <ApplicantTextField
          id="title"
          name="job title"
          label="Job title"
          disabled={disabled}
          defaultValue={value.jobTitle}
          getStatus={getTextRequiredStatus}
          onSyncToServer={(jobTitle) =>
            onChange({
              applicantEmploymentId: value.applicantEmploymentId,
              jobTitle,
            })
          }
        />
        <ApplicantDollarField
          id="Annual_income"
          name="Anual income"
          label="Annual income"
          disabled={disabled}
          defaultValue={getDollarByCents(value.annualIncomeInCents)}
          min={0}
          max={500000}
          getIsValid={(annualIncome) => annualIncome !== 0}
          onSyncToServer={(annualIncome) => {
            onChange({
              applicantEmploymentId: value.applicantEmploymentId,
              annualIncomeInCents: getCents(annualIncome),
            });
          }}
        />
      </VStack>
      <HStack
        w="100%"
        p={5}
        spacing={5}
        backgroundColor="gray.100"
        borderRadius={10}
        cursor="pointer"
        onClick={(e) => {
          e.preventDefault();
          onChange({
            applicantEmploymentId: value.applicantEmploymentId,
            isCurrent: !value.isCurrent,
          });
        }}
      >
        <Checkbox
          id="currentEmploymentCheckBox"
          value={value.isCurrent}
          onChange={(isCurrent) => {
            console.log("isCurrent", isCurrent);
            // onChange({
            //   applicantEmploymentId: value.applicantEmploymentId,
            //   isCurrent,
            // });
          }}
        />
        <Flex fontWeight="bold">I am currently working in this role.</Flex>
      </HStack>
      <VStack width="100%">
        <Flex w="100%" justifyContent="flex-start" fontWeight="bold">
          Employer Address
        </Flex>
        <ApplicantTextField
          id="street"
          name="street"
          label="Street address"
          disabled={disabled}
          defaultValue={value.streetAddress}
          getStatus={getTextRequiredStatus}
          onSyncToServer={(streetAddress) =>
            onChange({
              applicantEmploymentId: value.applicantEmploymentId,
              streetAddress,
            })
          }
        />

        <ApplicantTextField
          id="unitNumber"
          name="unit number"
          label="Unit Number"
          disabled={disabled}
          defaultValue={value.unitNumber}
          status={Status.Default}
          onSyncToServer={(unitNumber) =>
            onChange({
              applicantEmploymentId: value.applicantEmploymentId,
              unitNumber,
            })
          }
        />
        {value.country === "US" ? (
          <StateSelectField
            id="state"
            label="State"
            disabled={disabled}
            value={value.state}
            onChange={(e) =>
              onChange({
                applicantEmploymentId: value.applicantEmploymentId,
                state: e.target.value,
              })
            }
          />
        ) : (
          <ApplicantTextField
            id="state"
            name="state"
            label="State"
            disabled={disabled}
            defaultValue={value.state}
            getStatus={getTextRequiredStatus}
            onSyncToServer={(state) =>
              onChange({
                applicantEmploymentId: value.applicantEmploymentId,
                state,
              })
            }
          />
        )}
        <CountrySelectField
          id="country"
          label="Country"
          disabled={disabled}
          value={value.country}
          onChange={(e) =>
            onChange({
              applicantEmploymentId: value.applicantEmploymentId,
              state: "",
              country: e.target.value,
            })
          }
        />
        <ApplicantTextField
          id="city"
          name="city"
          label="City"
          disabled={disabled}
          defaultValue={value.city}
          getStatus={getTextRequiredStatus}
          onSyncToServer={(city) => {
            onChange({
              applicantEmploymentId: value.applicantEmploymentId,
              city,
            });
          }}
        />
        <ApplicantTextField
          id="zipcode"
          name="zipcode"
          label="Zip / postal code"
          disabled={disabled}
          defaultValue={value.zipcode}
          getStatus={(zipCode) => {
            return !isZipCodeValid(zipCode, value.country) ? Status.Error : getTextRequiredStatus(zipCode);
          }}
          transform={(zipCode) => replaceAllowedZipCode(zipCode, value.country)}
          onSyncToServer={(zipcode) =>
            onChange({
              applicantEmploymentId: value.applicantEmploymentId,
              zipcode,
            })
          }
        />
      </VStack>
    </VStack>
  );
}
