import { Box, Flex, VStack } from "@chakra-ui/react";
import { Progress, useIsMobile } from "@doorstead/components";
import { LayeredTimeline } from "@doorstead/components/containers/LayeredTimeline";
import React from "react";
import { useApplicantAnalytics } from "../../../../../hooks/applicants/useApplicantAnalytics";
import { useApplicantOnboardingStage } from "../../../../../hooks/applicants/useApplicantOnboardingStage";
import { ApplicantOnboardingStage } from "../../../../../hooks/applicants/useFetchApplicationMe/Applicant";
import { useProgress } from "../../../../../hooks/applicants/useProgress";
import {
  documentTitle,
  employmentTitle,
  getApplicantStatusByTargetId,
  submitAndPayTitle,
  useSteps,
  yourInformationTitle,
} from "../../../../../hooks/applicants/useSteps";
import { useUpdateApplicantOnboardingStage } from "../../../../../hooks/applicants/useUpdateApplicantOnboardingStage";
import { useScrollIntoView } from "../../../../../hooks/useScrollIntoView";
import { NegotiationInfoCard } from "../NegotiationInfoCard/NegotiationInfoCard";

function Hamburger({ onClick }: { onClick: () => void }) {
  return (
    <Flex as="button" justifyContent="center" alignItems="center" w="fit-content" onClick={() => onClick()}>
      <Flex flexDirection="column" justifyContent="space-between" alignItems="center" w="24px" h="16px">
        <Box w="20px" h="2px" bgColor="black" />
        <Box w="20px" h="2px" bgColor="black" />
        <Box w="20px" h="2px" bgColor="black" />
      </Flex>
    </Flex>
  );
}

function useTitle(): string {
  const onboardingStage = useApplicantOnboardingStage();
  switch (onboardingStage) {
    case ApplicantOnboardingStage.YOUR_INFORMATION_SECTION:
    default:
      return yourInformationTitle;
    case ApplicantOnboardingStage.DOCUMENTS_SECTION:
      return documentTitle;
    case ApplicantOnboardingStage.EMPLOYMENT_VERIFICATIONS_SECTION:
      return employmentTitle;
    case ApplicantOnboardingStage.SUBMIT_AND_PAY_SECTION:
      return submitAndPayTitle;
  }
}

export function Timeline() {
  const [isOpen, setOpen] = React.useState(false);
  const steps = useSteps();
  const scrollIntoView = useScrollIntoView();
  const isMobile = useIsMobile();
  const { mutate: updateOnboardingStage } = useUpdateApplicantOnboardingStage();
  const title = useTitle();
  const progress = useProgress();
  const applicantAnalytics = useApplicantAnalytics();

  if (isMobile) {
    return (
      <VStack
        w="100%"
        spacing={5}
        zIndex={2}
        borderRadius={20}
        bgColor="white"
        padding={5}
        pos="sticky"
        top={0}
        minW={250}
        minH="86px"
        mb={8}
        alignItems="flex-start"
        boxShadow="0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.10)"
      >
        <Flex w="100%" h={12} justifyContent="space-between">
          <Hamburger onClick={() => setOpen(!isOpen)} />
          <Flex pl={5} flexGrow={1} justifyContent="space-between" flexDirection="column">
            <Box>{title}</Box>
            <Progress value={progress} />
          </Flex>
        </Flex>
        <Box transition="max-height 0.2s ease-in-out" overflow="hidden" maxH={isOpen ? "500px" : 0}>
          <LayeredTimeline
            steps={steps}
            onStepClick={async (step) => {
              setOpen(false);
              const isRootStepClicked = step.id.split("/").slice(1).length === 1;
              if (isRootStepClicked) {
                applicantAnalytics?.track("TP_APPLICANT_TIMELINE_ROOT_CLICKED", { step: step.id });
                await updateOnboardingStage(getApplicantStatusByTargetId(step.id));
              } else {
                applicantAnalytics?.track("TP_APPLICANT_TIMELINE_SUB_STEP_CLICKED", { step: step.id });
              }
              scrollIntoView(step.id);
            }}
          />
        </Box>
        <NegotiationInfoCard />
      </VStack>
    );
  } else {
    return (
      <Box pos="sticky" minW={250} height="fit-content" top="15rem" right={0} overflowY="auto">
        <LayeredTimeline
          steps={steps}
          onStepClick={async (step) => {
            const isRootStepClicked = step.id.split("/").slice(1).length === 1;
            if (isRootStepClicked) {
              applicantAnalytics?.track("TP_APPLICANT_TIMELINE_ROOT_CLICKED", { step: step.id });
              await updateOnboardingStage(getApplicantStatusByTargetId(step.id));
            } else {
              applicantAnalytics?.track("TP_APPLICANT_TIMELINE_SUB_STEP_CLICKED", { step: step.id });
            }
            scrollIntoView(step.id);
          }}
        />
        <NegotiationInfoCard />
      </Box>
    );
  }
}
