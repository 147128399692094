import { useState } from "react";
import { GOOGLE_MAP_KEY } from "../../../../config";
import { Provider as GoogleMapProvider } from "../../../../hooks/useGoogleMapKey";
import { AddCoTenantsAndGuarantorsView } from "./AddCoTenantsAndGuarantorsView/AddCoTenantsAndGuarantorsView";
import { GetStartedView } from "./GetStartedView/GetStartedView";
import { InternalStateProvider } from "./useInternalState";
import { View, ViewProvider, useView } from "./useView";

function Content() {
  const { view } = useView();
  switch (view) {
    case View.GetStartedView:
      return <GetStartedView />;
    case View.AddCoTenantsAndGuarantorsView:
      return <AddCoTenantsAndGuarantorsView />;
    default:
      return null;
  }
}

export function PrimaryApplicantPreInCompleteCard() {
  const [howManyAdults, setHowManyAdults] = useState<number | null>(null);
  const [haveGuarantor, setHaveGuarantor] = useState<boolean | null>(null);

  return (
    <ViewProvider view={View.GetStartedView}>
      <GoogleMapProvider value={GOOGLE_MAP_KEY}>
        <InternalStateProvider
          value={{
            howManyAdults,
            setHowManyAdults,
            haveGuarantor,
            setHaveGuarantor,
          }}
        >
          <Content />
        </InternalStateProvider>
      </GoogleMapProvider>
    </ViewProvider>
  );
}
