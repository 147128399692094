import { isValidEmail } from "../../../utils/isValidEmail";
import { NewApplicantWithIdV2 } from "./NewApplicantsCardV2/typings";
import { getDuplicatedEmails } from "./getDuplicatedEmails";
import { getToAddApplicants } from "./getToAddApplicants";

type Args = {
  coTenants: NewApplicantWithIdV2[];
  guarantors: NewApplicantWithIdV2[];
  failureEmails: string[];
};

export function getIsValid({ failureEmails, coTenants, guarantors }: Args): boolean {
  const toAddTenants = getToAddApplicants(coTenants);
  const toAddGuarantors = getToAddApplicants(guarantors);
  const duplicatedEmails = getDuplicatedEmails({ coTenants, guarantors });
  if (duplicatedEmails.size > 0) {
    return false;
  }

  for (const tenant of toAddTenants.concat(toAddGuarantors)) {
    const email = tenant.email.trim();
    if (
      !tenant.firstName.trim() ||
      !tenant.lastName.trim() ||
      !tenant.email.trim() ||
      !isValidEmail(email) ||
      failureEmails.includes(email)
    ) {
      return false;
    }
  }
  return true;
}
