import { WalletIcon } from "@doorstead/components/icons";
import { useAutoPay } from "../../../../../hooks/useAutoPay";
import { usePaymentMethods } from "../../../../../hooks/usePaymentMethods";
import { IconBox } from "./IconBox";

export function PaymentAccountIconBox() {
  const autoPay = useAutoPay();
  const paymentMethods = usePaymentMethods();
  const firstPaymentMethod = paymentMethods.length === 0 ? null : paymentMethods[0];

  if (autoPay === null) {
    if (firstPaymentMethod !== null) {
      return (
        <IconBox
          title="Payment account"
          subTitle={`${firstPaymentMethod?.bankAccountName} *${firstPaymentMethod.bankAccountMask}`}
        >
          <WalletIcon color="gray.900" />
        </IconBox>
      );
    } else {
      return null;
    }
  }
  return (
    <IconBox
      title="Payment account"
      subTitle={`${autoPay.paymentMethod.bankAccountName} *${autoPay.paymentMethod.bankAccountMask}`}
    >
      <WalletIcon color="gray.900" />
    </IconBox>
  );
}
