import { ApplicantAnimalNode } from "../../../../../../hooks/applicants/useFetchApplicationMe/Applicant";
import { ValuesCard } from "../../ValuesCard";
import { Content } from "./Content";
import { Props } from "./typings";

export function PetsCard({ id, onChange, onRemove, subTitle, ...valuesProps }: Props) {
  return (
    <ValuesCard
      {...valuesProps}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      idField="applicantAnimalId"
      id={id}
      eventName="PET"
      title="Pets"
      subTitle={subTitle}
      noLabel="No pets"
      yesLabel="Add pets"
      numberLabel="Pet"
      addLabel="Add more pets"
      content={(_ignored, value) => <Content value={value as ApplicantAnimalNode} onChange={onChange} />}
      onRemove={(_ignored, value) => {
        onRemove((value as ApplicantAnimalNode).applicantAnimalId);
      }}
    />
  );
}
