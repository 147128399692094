import { AddUserIcon, LogoutIcon } from "@doorstead/components";
import { ReactNode } from "react";
import { useIsApplicantEditable } from "src/hooks/applicants/useIsApplicantEditable";
import { useAuth } from "../../../contexts/auth";
import { useApplicantId } from "../../../hooks/applicants/useApplicantId";
import { useFetchedApplicationMe } from "../../../hooks/applicants/useFetchedApplicationMe";
import { useIsShowInvitedCoTenantLink } from "../../../hooks/applicants/useIsShowInvitedCoTenantLink";
import { useFooter } from "../../../hooks/useFooter";
import { Name } from "../../../hooks/useHeader";
import { MainLayout } from "../../MainLayout";

type Props = {
  children: ReactNode;
};

export function ApplicantLayout({ children }: Props) {
  const { user } = useAuth();
  const footer = useFooter();
  const applicantId = useApplicantId();
  const isEditable = useIsApplicantEditable();
  const applicationMe = useFetchedApplicationMe();
  const isLogin = !!user;
  const links = [];
  const isFlex = applicationMe?.applicantById?.applicationRequest?.property?.isFlex || false;
  const isTpo = applicationMe?.applicantById?.applicationRequest?.property?.isTpo || false;
  const isShowInvitedCoTenantLink = useIsShowInvitedCoTenantLink();

  if (isLogin) {
    if (isEditable && isShowInvitedCoTenantLink) {
      links.push({
        name: Name.InviteCoTenant,
        to: `/application/${applicantId}/invite`,
        icon: <AddUserIcon size={31} color="gray.700" />,
      });
    }

    links.push({
      name: Name.LogOut,
      to: `/application/logout?applicantId=${applicantId}`,
      icon: <LogoutIcon size={31} color="gray.700" />,
    });
  }

  const header = {
    logoLink: `/application/${applicantId}`,
    avatarName: isLogin ? user!.firstName || "" : "",
    links,
  };

  return (
    <MainLayout header={header} footer={footer} isFlex={isFlex} isTPO={isTpo}>
      {children}
    </MainLayout>
  );
}
