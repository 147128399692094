import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/auth";
import { ApplicantResidenceNode, useFetchApplicationMe } from "./useFetchApplicationMe";
import { useFetchedApplicant } from "./useFetchedApplicant";

type Args = Omit<ApplicantResidenceNode, "applicantResidenceId">;

export function useCreatePreviousResidence() {
  const { gqlClient } = useAuth();
  const { refetch } = useFetchApplicationMe();
  const applicantId = useFetchedApplicant()!.applicantId;

  return useMutation(
    async ({ addressLine1, addressLine2, city, state, country, zipcode }: Args) => {
      await gqlClient.mutate({
        mutation: gql`
          mutation ($applicantResidence: ApplicantResidenceCreateData) {
            createApplicantPreviousResidence(applicantResidence: $applicantResidence) {
              applicantResidenceId
            }
          }
        `,
        variables: {
          applicantResidence: {
            addressLine1,
            addressLine2,
            city,
            state,
            country,
            zipcode,
            applicantId,
          },
        },
      });
    },
    {
      async onSuccess() {
        await refetch();
      },
    }
  );
}
