import { useState } from "react";
import "react-day-picker/dist/style.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSuccess } from "src/hooks/useSuccess";
import { ApplicantNodeStatus } from "../../../hooks/applicants/useFetchApplicationMe/Applicant";
import { useFetchedApplicant } from "../../../hooks/applicants/useFetchedApplicant";
import { useIsCoTenantsInviteV2Feature } from "../../../hooks/applicants/useIsCoTenantsInviteV2Feature";
import { useIsInvitedCoTenantsAndGuarantors } from "../../../hooks/applicants/useIsInvitedCoTenantsAndGuarantors";
import { useIsPrimaryApplicant } from "../../../hooks/applicants/useIsPrimaryApplicant";
import { useStartApplicant } from "../../../hooks/applicants/useStartApplicant";
import { useRenderOnce } from "../../../hooks/useRenderOnce";
import { Error } from "../../Error";
import { ApplicantInCompleteCard } from "./ApplicantInCompleteCard";
import { ApplicantNewCard } from "./ApplicantNewCard";
import { ApplicantSubmittedCard } from "./ApplicantSubmittedCard";
import { PrimaryApplicantPreInCompleteCard } from "./PrimaryApplicantPreInCompleteCard/PrimaryApplicantPreInCompleteCard";

export function ApplicantOneSteps() {
  const navigate = useNavigate();
  const isCoTenantsInviteV2Feature = useIsCoTenantsInviteV2Feature();
  const [isLandingPage, setIsLandingPage] = useState(true);
  const { isLoading, mutate: startApplication } = useStartApplicant();
  const [params] = useSearchParams();
  const isInvited = params.get("invited") === "true";
  const success = useSuccess();
  const isPrimaryApplicant = useIsPrimaryApplicant();
  const isInvitedCoTenantsAndGuarantors = useIsInvitedCoTenantsAndGuarantors();
  useRenderOnce(() => {
    if (isInvited) {
      success({ title: "Co-tenants and guarantors invited" });
      //remove isInvited from the url
      navigate({ search: "" });
    }
  });
  const applicant = useFetchedApplicant();
  if (applicant.status === ApplicantNodeStatus.NEW) {
    return (
      <ApplicantNewCard
        isLoading={isLoading}
        onContinue={async () => {
          await startApplication();
          setIsLandingPage(false);
        }}
      />
    );
  } else if (applicant.status === ApplicantNodeStatus.INCOMPLETE) {
    if (isCoTenantsInviteV2Feature && isPrimaryApplicant && !isInvitedCoTenantsAndGuarantors) {
      return <PrimaryApplicantPreInCompleteCard />;
    }
    return (
      <ApplicantInCompleteCard
        isLandingPage={isLandingPage}
        onContinue={() => setIsLandingPage(false)}
        onBack={() => setIsLandingPage(true)}
      />
    );
  } else if (applicant.status === ApplicantNodeStatus.SUBMITTED) {
    return <ApplicantSubmittedCard />;
  } else {
    return <Error title="We can not get the status for this applicant" />;
  }
}
