import { Box, Divider, Flex, VStack } from "@chakra-ui/react";
import React from "react";

type Row = {
  id: string;
  title: string;
  component: React.FC;
};

type Props = {
  title: string;
  rows: Row[];
};

export function EditRow({ title, rows }: Props) {
  return (
    <VStack
      bgColor="gray.50"
      color="gray.800"
      lineHeight="24px"
      padding={5}
      w="100%"
      alignItems="flex-start"
      spacing={5}
      borderRadius="6px"
    >
      <Box>{title}</Box>
      <Divider />
      {rows.map(({ id, title, component }) => (
        <Flex key={id} w="100%" justifyContent="space-between" alignItems="center">
          <Box>{title}</Box>
          {component({})}
        </Flex>
      ))}
    </VStack>
  );
}
