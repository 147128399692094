import { useCreatePreviousResidence } from "../../../../../../hooks/applicants/useCreatePreviousResidence";
import { useFetchedApplicant } from "../../../../../../hooks/applicants/useFetchedApplicant";
import { useRemovePreviousResidence } from "../../../../../../hooks/applicants/useRemovePreviousResidence";
import { useUpdatePreviousResidence } from "../../../../../../hooks/applicants/useUpdatePreviousResidence";
import { maxWidth } from "../../../config";
import { ValuesCard } from "../../ValuesCard";
import { Residence, ResidenceCard } from "../ResidenceCard";
import { Props } from "./typings";

export function PreviousResidenceCard({ padding, onContinue, id }: Props) {
  const applicant = useFetchedApplicant()!;
  const { mutate: createPreviousResidence } = useCreatePreviousResidence();
  const { mutate: updatePreviousResidence } = useUpdatePreviousResidence();
  const { mutate: removePreviousResidence } = useRemovePreviousResidence();

  const previousResidencesEdges = applicant.previousResidences.edges;

  const values: Residence[] = [];
  previousResidencesEdges.forEach(({ node }) => {
    values.push({
      applicantResidenceId: node.applicantResidenceId,
      streetAddress: node.addressLine1,
      unitNumber: node.addressLine2,
      state: node.state,
      city: node.city,
      country: node.country,
      zipCode: node.zipcode,
      moveInDate: node.moveInDate,
      moveOutDate: node.moveOutDate,
      landlordName: node.landlordName,
      landlordPhone: node.landlordPhone,
      landlordEmail: node.landlordEmail,
    });
  });

  return (
    <ValuesCard
      id={id}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      idField="applicantResidenceId"
      eventName="PREVIOUS_RESIDENCE"
      values={values}
      padding={padding}
      width="100%"
      title="Previous residence"
      subTitle="Provide the previous residences of the tenant up to 24 months back. "
      noLabel="No previous residences"
      yesLabel="Add previous residence"
      numberLabel="Residence"
      addLabel="Add more residences"
      content={(index, value) => {
        const {
          applicantResidenceId,
          streetAddress: addressLine1,
          unitNumber: addressLine2,
          city,
          state,
          country,
          zipCode: zipcode,
          moveInDate,
          moveOutDate,
          landlordName,
          landlordPhone,
          landlordEmail,
        } = value as Residence;

        return (
          <ResidenceCard
            padding={0}
            width="100%"
            streetAddress={addressLine1}
            unitNumber={addressLine2}
            state={state}
            city={city}
            country={country}
            zipCode={zipcode}
            moveInDate={moveInDate}
            moveOutDate={moveOutDate}
            landlordName={landlordName}
            landlordPhone={landlordPhone}
            landlordEmail={landlordEmail}
            onStreetAddressChange={async (addressLine1) => {
              await updatePreviousResidence({
                applicantResidenceId,
                addressLine1,
              });
            }}
            onUnitNumberChange={async (unitNumber) => {
              await updatePreviousResidence({
                applicantResidenceId,
                addressLine2: unitNumber,
              });
            }}
            onStateChange={async (state) => {
              await updatePreviousResidence({
                applicantResidenceId,
                state,
              });
            }}
            onCityChange={async (city) => {
              await updatePreviousResidence({
                applicantResidenceId,
                city,
              });
            }}
            onCountryChange={async (country) => {
              await updatePreviousResidence({
                applicantResidenceId,
                country,
              });
            }}
            onZipCodeChange={async (zipcode) => {
              await updatePreviousResidence({
                applicantResidenceId,
                zipcode,
              });
            }}
            onMoveInDateChange={async (moveInDate) => {
              await updatePreviousResidence({
                applicantResidenceId,
                moveInDate,
              });
            }}
            onMoveOutDateChange={async (moveOutDate) => {
              await updatePreviousResidence({
                applicantResidenceId,
                moveOutDate,
              });
            }}
            onLandlordNameChange={async (landlordName) => {
              await updatePreviousResidence({
                applicantResidenceId,
                landlordName,
              });
            }}
            onLandlordPhoneChange={async (landlordPhone) => {
              await updatePreviousResidence({
                applicantResidenceId,
                landlordPhone,
              });
            }}
            onLandlordEmailChange={async (landlordEmail) => {
              await updatePreviousResidence({
                applicantResidenceId,
                landlordEmail,
              });
            }}
          />
        );
      }}
      onRemove={async (ignored, data) => {
        const { applicantResidenceId } = data as Residence;
        await removePreviousResidence(applicantResidenceId);
      }}
      maxWidth={maxWidth}
      onReset={async () => {
        if (previousResidencesEdges.length > 0) {
          const applicantResidenceId = applicant.previousResidences.edges[0].node.applicantResidenceId;
          await removePreviousResidence(applicantResidenceId);
        }
      }}
      onAdd={() => {
        createPreviousResidence({
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          country: "US",
          zipcode: "",
          moveInDate: "",
          moveOutDate: "",
          landlordName: "",
          landlordPhone: "",
          landlordEmail: "",
        });
      }}
      onContinue={onContinue}
    />
  );
}
