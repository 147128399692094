import { Box, StackProps, VStack } from "@chakra-ui/react";
import { StateSelectField } from "@doorstead/components";
import { Card } from "@doorstead/components/Card";
import { CountrySelectField } from "@doorstead/components/fields/CountrySelectField";
import { Status } from "@doorstead/components/fields/DateField";
import _ from "lodash";
import { useIsApplicantEditable } from "../../../../../../hooks/applicants/useIsApplicantEditable";
import { getTextRequiredStatus } from "../../../../../../utils/getTextRequiredStatus";
import { ApplicantDateField } from "../../../../ApplicantDateField";
import { ApplicantTextField } from "../../../../ApplicantTextField";
import { isZipCodeValid } from "./isZipCodeValid";
import { replaceAllowedZipCode } from "./replaceAllowedZipCode";

export type Residence = {
  applicantResidenceId: string;
  streetAddress: string;
  unitNumber: string;
  state: string;
  city: string;
  country: string;
  zipCode: string;
  moveInDate: string | null;
  moveOutDate: string | null;
  landlordName: string;
  landlordPhone: string;
  landlordEmail: string;
};

export type Props = StackProps &
  Omit<Residence, "applicantResidenceId"> & {
    padding: number;
    width: number | string;
    onStreetAddressChange: (value: string) => void;
    onUnitNumberChange: (value: string) => void;
    onStateChange: (value: string) => void;
    onCityChange: (value: string) => void;
    onCountryChange: (value: string) => void;
    onZipCodeChange: (value: string) => void;
    onMoveInDateChange: (value: string | null) => void;
    onMoveOutDateChange: (value: string | null) => void;
    onLandlordNameChange: (value: string) => void;
    onLandlordPhoneChange: (value: string) => void;
    onLandlordEmailChange: (value: string) => void;
  };

export function ResidenceCard({
  padding,
  width,
  streetAddress,
  unitNumber,
  state,
  city,
  country,
  zipCode,
  moveInDate,
  moveOutDate,
  landlordName,
  landlordPhone,
  landlordEmail,
  onStreetAddressChange,
  onUnitNumberChange,
  onStateChange,
  onCityChange,
  onCountryChange,
  onZipCodeChange,
  onMoveInDateChange,
  onMoveOutDateChange,
  onLandlordNameChange,
  onLandlordPhoneChange,
  onLandlordEmailChange,
}: Props) {
  const disabled = !useIsApplicantEditable();

  return (
    <Card padding={padding} width={width}>
      <VStack width="100%" alignItems="flex-start">
        <ApplicantTextField
          id="streetAddress"
          name="street address"
          label="Street address"
          disabled={disabled}
          defaultValue={streetAddress}
          getStatus={getTextRequiredStatus}
          onSyncToServer={onStreetAddressChange}
        />
        <ApplicantTextField
          id="unitNumber"
          name="Unit number"
          label="Unit number"
          disabled={disabled}
          defaultValue={unitNumber}
          status={Status.Default}
          onSyncToServer={onUnitNumberChange}
        />
        {country === "US" ? (
          <StateSelectField
            id="state"
            label="State"
            disabled={disabled}
            value={state}
            onChange={(e) => onStateChange(e.target.value)}
          />
        ) : (
          <ApplicantTextField
            id="state"
            name="state"
            label="State"
            disabled={disabled}
            defaultValue={state}
            getStatus={getTextRequiredStatus}
            onSyncToServer={onStateChange}
          />
        )}
        <CountrySelectField
          id="country"
          label="Country"
          disabled={disabled}
          value={country}
          onChange={(e) => {
            onCountryChange(e.target.value);
            onStateChange("");
          }}
        />
        <ApplicantTextField
          id="city"
          label="City"
          name="city"
          disabled={disabled}
          defaultValue={city}
          getStatus={getTextRequiredStatus}
          transform={_.deburr}
          onSyncToServer={onCityChange}
        />
        <ApplicantTextField
          id="zipCode"
          name="zipcode"
          label="Zip / postal code"
          disabled={disabled}
          defaultValue={zipCode}
          getStatus={(zipCode) => {
            return !isZipCodeValid(zipCode, country) ? Status.Error : getTextRequiredStatus(zipCode);
          }}
          transform={(zipCode) => replaceAllowedZipCode(zipCode, country)}
          onSyncToServer={onZipCodeChange}
        />
        <Box w="100%">
          <ApplicantDateField
            id="move-in"
            label="Current address move-in date"
            fromYear={new Date().getFullYear() - 100}
            toYear={new Date().getFullYear()}
            disabled={disabled}
            value={moveInDate}
            status={
              (moveInDate && moveOutDate && moveInDate > moveOutDate
                ? Status.Error
                : getTextRequiredStatus(moveInDate)) as Status
            }
            onChange={onMoveInDateChange}
          />
        </Box>
        <Box w="100%">
          <ApplicantDateField
            id="move-out"
            label="Current address move-out date"
            fromYear={new Date().getFullYear() - 100}
            toYear={new Date().getFullYear()}
            disabled={disabled}
            value={moveOutDate}
            status={
              moveInDate && moveOutDate && moveInDate > moveOutDate
                ? Status.Error
                : (getTextRequiredStatus(moveOutDate) as Status)
            }
            onChange={onMoveOutDateChange}
          />
        </Box>
        <Box color="gray.800" fontWeight="bold" lineHeight="24px">
          Landlord contact information
        </Box>
        <ApplicantTextField
          id="landlord-Name"
          label="Name"
          name="name"
          disabled={disabled}
          defaultValue={landlordName}
          getStatus={getTextRequiredStatus}
          onSyncToServer={onLandlordNameChange}
        />
        <ApplicantTextField
          id="landlord-phone"
          label="Phone"
          name="phone"
          disabled={disabled}
          defaultValue={landlordPhone}
          getStatus={getTextRequiredStatus}
          onSyncToServer={onLandlordPhoneChange}
        />
        <ApplicantTextField
          id="landlord-email"
          label="Email"
          name="email"
          disabled={disabled}
          defaultValue={landlordEmail}
          getStatus={getTextRequiredStatus}
          onSyncToServer={onLandlordEmailChange}
        />
      </VStack>
    </Card>
  );
}
