import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import { IdIcon } from "@doorstead/components";
import { Card, Props as CardProps } from "@doorstead/components/Card";
import { GrayText } from "@doorstead/components/GrayText";
import { PrimaryButton } from "@doorstead/components/buttons";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { Status, StatusIcon } from "@doorstead/components/icons/StatusIcon";
import Persona from "persona";
import React, { useCallback } from "react";
import { personaEnvironmentId } from "src/config";
import { useApplicantAnalytics } from "src/hooks/applicants/useApplicantAnalytics";
import { useFetchedApplicant } from "src/hooks/applicants/useFetchedApplicant";
import { useUpdateApplicantFromPersona } from "src/hooks/applicants/useUpdateApplicantFromPersona";
import { CardTitle } from "../../../../CardTitle";

export type Props = Omit<CardProps, "children"> & {
  width: number | string;
  padding: number;
  onContinue: () => void;
};

export const PersonaPhotoIdCard = React.forwardRef(({ padding, width, onContinue, ...props }: Props, ref) => {
  const applicantAnalytics = useApplicantAnalytics();
  const isMobile = useIsMobile();
  const applicant = useFetchedApplicant()!;
  const { mutate: updateApplicantFromPersona, isLoading: updateApplicantFromPersonaLoading } =
    useUpdateApplicantFromPersona();
  const isPhotoIdUploaded = applicant.personaInquiryId !== null;

  const createPersonaClient = useCallback(() => {
    return new Persona.Client({
      templateId: "itmpl_1CbvGw4eY6MDnsDcWMYF2iLk",
      environmentId: personaEnvironmentId(),
      referenceId: applicant.doorsteadUser.doorsteadUserId,
      onLoad: () => {
        applicantAnalytics?.track("TP_PERSONA_ON_LOAD");
      },
      onReady: () => {
        applicantAnalytics?.track("TP_PERSONA_ON_READY");
      },
      onComplete: ({ inquiryId, status, fields }) => {
        console.log("Persona onComplete", { inquiryId, status, fields });
        updateApplicantFromPersona({ inquiryId });
        applicantAnalytics?.track("TP_PERSONA_ON_COMPLETE", { inquiryId, status });
      },
      onCancel: ({ inquiryId }) => {
        console.log("Persona onCancel", { inquiryId });
        applicantAnalytics?.track("TP_PERSONA_ON_CANCEL", { inquiryId });
      },
      onError: (error) => {
        console.log("Persona onError", error);
        applicantAnalytics?.track("TP_PERSONA_ON_ERROR", { error });
      },
    });
  }, [applicant]);

  return (
    <Card {...props} padding={padding} width={width} ref={ref}>
      <VStack spacing={4} width="100%" alignItems="flex-start">
        <CardTitle>Photo ID</CardTitle>
        <GrayText>Upload a valid identification card such as a driver&apos;s license or passport</GrayText>
        <Flex
          w="100%"
          bgColor="gray.50"
          color="gray.800"
          lineHeight="24px"
          padding={5}
          borderRadius="6px"
          justifyContent="space-between"
          alignItems="center"
        >
          <HStack spacing={3}>
            <IdIcon />
            <Box>{isPhotoIdUploaded ? "Your ID is verified" : "Valid ID card"}</Box>
          </HStack>
          {isPhotoIdUploaded && <StatusIcon status={Status.Correct} />}
        </Flex>

        {!isPhotoIdUploaded ? (
          <PrimaryButton
            isLoading={updateApplicantFromPersonaLoading}
            isFullWidth={isMobile}
            onClick={() => {
              applicantAnalytics?.track("TP_PHOTO_ID_CARD_UPLOAD_CLICKED");
              createPersonaClient().open();
            }}
          >
            Upload
          </PrimaryButton>
        ) : (
          <PrimaryButton isFullWidth={isMobile} onClick={onContinue}>
            Continue
          </PrimaryButton>
        )}
      </VStack>
    </Card>
  );
});
