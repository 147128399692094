import { gql } from "@apollo/client";
import { useQuery } from "react-query";
import { useAnalytics } from "src/contexts/analytics";
import { AutoPayDate } from "../@types/AutoPay/typings";
import { useAuth } from "../contexts/auth";

export const queryKey = "useFetchMe";

export enum PaymentMethodType {
  ACH = "ACH",
  Card = "Card",
}

export type PaymentMethodEdgeNode = {
  paymentMethodId: string;
  type_: PaymentMethodType;
  isDefault: boolean;
  bankAccountMask: string | null;
  bankAccountName: string | null;
  microDepositStatus: string | null;
  cardBrand: string | null;
  cardLastFourDigits: string | null;
};

export type LedgerTransaction = {
  effectiveDate: string;
  status: "pending" | "posted" | "archived";
  category: string;
  description: string | null;
};

export type ReceivableLedgerEntryHistory = {
  ledgerTransactionId: string;
  amountInCents: number;
  direction: "debit" | "credit";
  resultingAvailableAccountBalanceInCents: number | null;
  ledgerTransaction: LedgerTransaction;
};

export type Property = {
  propertyId: string;
  fullAddress: string;
  addressLine1: string;
  addressLine2: string | null;
  city: string;
  state: string;
  zip: string;
  isFlex: boolean | null;
  isTenantPortalTpoFeature: boolean | null;
  isDefaultAutopayFeature: boolean | null;
  unifiedSecdepCollectionProcess: boolean | null;
  publicOwnerBasicInfo: {
    fullName: string | null;
    email: string | null;
    phone: string | null;
  } | null;
  activeContract: {
    contractId: string | null;
    type: string | null;
    isTpo: boolean | null;
  } | null;
};

export type Lease = {
  leaseId: string;
  startDate: string;
  endDate: string;
  rent: number;
  utilities: number | null;
  holdingDeposit: number;
  securityDeposit: number;
  proratedRentDueDate: string;
  securityDepositDueDate: string;
  walkedThroughAt: string | null;
  property: Property;
  tenantGroup: {
    requireFmrUpfront: boolean | null;
    isPreExisting: boolean;
    tenantGroupId: string;
    secdepDueInCents: number;
    holdingDepDueInCents: number;
    rentDueInCents: number;
    proratedRentDueInCents: number;
    receivableLedgerEntryHistory: ReceivableLedgerEntryHistory[];
    tenantGroupMembers: {
      edges: {
        node: {
          tenant: {
            doorsteadUser: {
              lastName: string;
              firstName: string;
            };
          };
        };
      }[];
    };
  };
};

export type AutoPayNodeStatus = "active";

export type AutoPayNode = {
  autopayId: string;
  date: AutoPayDate;
  paymentMethod: PaymentMethodEdgeNode;
  status: AutoPayNodeStatus;
  nextPaymentDate: string | null;
};

export type MeNode = {
  doorsteadUserId: string;
  email: string;
  firstName: string;
  lastName: string;
  stripePlatformCustomerId: string | null;
  paymentMethods: {
    edges: { node: PaymentMethodEdgeNode }[];
  };
  tenant: {
    onboardingStage: string;
    tenantId: string;
    achDisclaimerAt: string | null;
    doorsteadUserId: string;
    activeLease: Lease;
    activeAutopay: AutoPayNode | null;
  };
};

export type MeResult = {
  me: MeNode;
};

export const autopayFragment = `
  activeAutopay {
    autopayId
    date
    status
    nextPaymentDate
    paymentMethod {
      paymentMethodId
      type_
      isDefault
      bankAccountName
      bankAccountMask
      microDepositStatus
      cardBrand
      cardLastFourDigits
    }
  }
`;

export const paymentMethodsFragment = `
  paymentMethods {
    edges {
      node {
        paymentMethodId
        type_
        isDefault
        bankAccountName
        bankAccountMask
        microDepositStatus
        cardBrand
        cardLastFourDigits
      }
    }
  }
`;

const query = gql`
  query GetTenant {
    me {
      doorsteadUserId
      email
      firstName
      lastName
      stripePlatformCustomerId
      ${paymentMethodsFragment}
      tenant {
        tenantId
        doorsteadUserId
        onboardingStage
        achDisclaimerAt
        ${autopayFragment}
        activeLease {
          leaseId
          startDate
          endDate
          rent
          utilities
          holdingDeposit
          securityDeposit
          proratedRentDueDate
          securityDepositDueDate
          walkedThroughAt
          property {
            propertyId
            fullAddress
            addressLine1
            addressLine2
            unifiedSecdepCollectionProcess
            city
            state
            zip
            isFlex
            isTenantPortalTpoFeature
            isDefaultAutopayFeature
            publicOwnerBasicInfo {
              fullName
              email
              phone
            }
            activeContract {
              contractId
              type
              isTpo
            }
          }
          tenantGroup {
            requireFmrUpfront
            tenantGroupId
            isPreExisting
            secdepDueInCents
            holdingDepDueInCents
            rentDueInCents
            proratedRentDueInCents
            receivableLedgerEntryHistory {
              ledgerTransactionId
              amountInCents
              direction
              resultingAvailableAccountBalanceInCents
              ledgerTransaction {
                effectiveDate
                status
                category
                description
              }
            }
            tenantGroupMembers {
              edges {
                node {
                  tenant {
                    doorsteadUser {
                      lastName
                      firstName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export function useFetchMe() {
  const { gqlClient } = useAuth();
  const analytics = useAnalytics();
  const { data, ...rest } = useQuery<unknown, unknown, MeResult>(queryKey, async () => {
    const result: MeResult = await gqlClient.query({ query }).then((e) => e.data);

    const tenantGroup = result?.me?.tenant?.activeLease?.tenantGroup;
    const tenantGroupId = tenantGroup?.tenantGroupId;

    analytics?.identify(result?.me.email, {
      firstName: result?.me.firstName,
      lastName: result?.me.lastName,
      email: result?.me.email,
      doorsteadUserId: result?.me.doorsteadUserId,
      tenantId: result?.me.tenant?.tenantId,
      tenantGroupId,
      autopayId: result?.me?.tenant?.activeAutopay?.autopayId,
      defaultPaymentMethodType: result?.me?.paymentMethods?.edges?.find((e) => e.node.isDefault)?.node.type_,
      onboardingStage: result?.me?.tenant?.onboardingStage,
    });

    analytics?.group(tenantGroupId, {
      tenant_group_id: tenantGroupId,
      isPreExisting: tenantGroup?.isPreExisting,
      groupKey: "tenant_group_id",
    });

    return result;
  });

  return { data, ...rest };
}
