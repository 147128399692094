import { gql } from "@apollo/client";
import produce from "immer";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "../../contexts/auth";
import { ApplicantResidenceNode, Result, queryKey } from "./useFetchApplicationMe";

export function useUpdatePreviousResidence() {
  const queryClient = useQueryClient();
  const { gqlClient } = useAuth();

  return useMutation(
    async (
      applicantResidence: Partial<ApplicantResidenceNode> & {
        applicantResidenceId: ApplicantResidenceNode["applicantResidenceId"];
      }
    ) => {
      queryClient.setQueryData(
        queryKey,
        produce(queryClient.getQueryData(queryKey), (draft: Result) => {
          draft.applicantById.previousResidences.edges.forEach((edge) => {
            if (edge.node.applicantResidenceId === applicantResidence.applicantResidenceId) {
              edge.node = Object.assign(edge.node, applicantResidence);
            }
          });
        })
      );

      await gqlClient.mutate({
        mutation: gql`
          mutation ($applicantResidence: ApplicantResidenceUpdateData) {
            updateApplicantPreviousResidence(applicantResidence: $applicantResidence) {
              count
            }
          }
        `,
        variables: {
          applicantResidence: {
            ...applicantResidence,
            applicantResidenceId: applicantResidence.applicantResidenceId,
          },
        },
      });
    }
  );
}
