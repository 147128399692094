import { Form as DoorsteadLoginForm } from "@doorstead/components/screens/LoginScreen";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { processAuthError } from "src/contexts/auth";
import { ApplicantPortalProvider } from "../../components/applicants/ApplicantPortalProvider";
import { useApplicantId } from "../../hooks/applicants/useApplicantId";
import { useApplicantLoginWithPassword } from "../../hooks/applicants/useApplicantLoginWithPassword";
import { useApplicantPageView } from "../../hooks/applicants/useApplicantPageView";
import { useRedirectIfAuthenticated } from "../../hooks/useRedirectIfAuthenticated";
import { ApplicantAuthWhiteBox } from "./ApplicantAuthWhiteBox";

function ApplicantLoginPage() {
  const applicantId = useApplicantId();
  const applicantAnalytics = useApplicantPageView("TP_APPLICANT_LOGIN_VIEWED");
  useRedirectIfAuthenticated({ to: `/application/${applicantId}` });
  // https://auth0.com/docs/customize/email/email-templates/email-template-descriptions
  const [params] = useSearchParams();
  const success = params.get("success");
  const message = params.get("message") || "";
  const [email, setEmail] = useState(params.get("email") || "");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const loginWithPassword = useApplicantLoginWithPassword(applicantId);
  const successMessage = success ? message : "";

  const passwordLogin = async () => {
    try {
      setLoading(true);
      applicantAnalytics?.track("TP_APPLICANT_LOGIN_SUBMITTED", { email });
      await loginWithPassword({ email, password });
      // Redirect to login/callback
    } catch (err: any) {
      const authError = processAuthError(err);
      setEmailError(authError.email || "");
      setPasswordError(authError.password || "");
      setErrorMessage(authError.general || "");
      applicantAnalytics?.track("TP_APPLICANT_LOGIN_FAILED", { email });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ApplicantPortalProvider>
      <ApplicantAuthWhiteBox>
        <DoorsteadLoginForm
          hasGoogleLogin={false}
          hasFacebookLogin={false}
          emailDisabled={false}
          value={{ email, password }}
          onChange={({ email, password }) => {
            setEmail(email);
            setPassword(password);
          }}
          onLogin={passwordLogin}
          isSubmitting={loading}
          emailErrorMessage={emailError}
          passwordErrorMessage={passwordError}
          errorMessage={errorMessage}
          successMessage={successMessage}
        />
      </ApplicantAuthWhiteBox>
    </ApplicantPortalProvider>
  );
}

export default ApplicantLoginPage;
