import { Flex, HStack, VStack, useToken } from "@chakra-ui/react";
import { OutlineButton } from "@doorstead/components";
import { Chip, Variant } from "@doorstead/components/Chip";
import { useGroupAnalytics } from "src/hooks/useGroupAnalytics";
import { getPaymentMethodTitle } from "src/utils/getPaymentMethodTitle";
import { PaymentMethodEdgeNode } from "../../hooks/useFetchMe";
import { useRemovePaymentMethod } from "../../hooks/useRemovePaymentMethod";
import { useSetPaymentMethodDefault } from "../../hooks/useSetPaymentMethodDefault";
import { getPaymentMethodLabel } from "../../utils/getPaymentMethodLabel";
import { VerifyACHButton } from "../VerifyACHButton";
import { RemoveButton } from "./RemoveButton";

type Props = PaymentMethodEdgeNode;

export function PaymentMethod({
  paymentMethodId,
  type_,
  bankAccountName,
  bankAccountMask,
  microDepositStatus,
  cardBrand,
  cardLastFourDigits,
  isDefault = false,
}: Props) {
  const { setPaymentMethodDefault, loading: defaultPaymentMethodLoading } = useSetPaymentMethodDefault();
  const { loading: removePaymentMethodLoading } = useRemovePaymentMethod();
  const shadowColor = useToken("colors", ["gray.200"]);
  const analytics = useGroupAnalytics();

  return (
    <Flex
      w="100%"
      justifyContent="space-between"
      alignItems="center"
      padding={3}
      borderWidth={1}
      borderRadius={6}
      bgColor="gray.50"
      borderColor="gray.50"
      borderBottomColor="gray.200"
      boxShadow={`1px 1px ${shadowColor}`}
    >
      <VStack justifyContent="flex-start" alignItems="center">
        <Flex alignItems="center" w="100%" h="100%" color="gray.400" fontSize="xs">
          {getPaymentMethodTitle({ type: type_ })}
        </Flex>
        <Flex alignItems="center" w="100%" h="100%" color="gray.700" fontWeight="bold" fontSize="sm">
          {getPaymentMethodLabel({ type: type_, bankAccountName, bankAccountMask, cardBrand, cardLastFourDigits })}
        </Flex>
      </VStack>
      <HStack h="100%" alignItems="center" justifyContent="flex-end" gap={{ sm: 1, md: 3 }} flexWrap="wrap">
        {isDefault ? (
          <Chip label="Default" variant={Variant.Gray} />
        ) : microDepositStatus === null ? (
          <OutlineButton
            size="sm"
            disabled={defaultPaymentMethodLoading || removePaymentMethodLoading}
            isLoading={defaultPaymentMethodLoading}
            onClick={async () => {
              analytics?.track("TP_SET_PAYMENT_METHOD_DEFAULT_CLICKED", {
                paymentMethodId,
              });
              if (window.confirm("Are you sure to set this as default payment method?")) {
                await setPaymentMethodDefault(paymentMethodId);
              }
            }}
          >
            Set as Default
          </OutlineButton>
        ) : (
          <VerifyACHButton
            mt={6}
            paymentMethodId={paymentMethodId}
            onDone={() => {
              analytics?.track("TP_OB_PAYMENT_METHOD_VERIFIED");
            }}
          />
        )}
        <RemoveButton paymentMethodId={paymentMethodId} />
      </HStack>
    </Flex>
  );
}
