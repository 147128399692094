import { gql } from "@apollo/client";
import { useMutation } from "react-query";
import { useAuth } from "../../contexts/auth";
import { ApplicantResidenceNode, useFetchApplicationMe } from "./useFetchApplicationMe";

export function useRemovePreviousResidence() {
  const { gqlClient } = useAuth();
  const { refetch } = useFetchApplicationMe();

  return useMutation(
    async (applicantResidenceId: ApplicantResidenceNode["applicantResidenceId"]) => {
      await gqlClient.mutate({
        mutation: gql`
          mutation ($applicantResidenceId: UUID) {
            deleteApplicantPreviousResidence(applicantResidenceId: $applicantResidenceId) {
              result
            }
          }
        `,
        variables: {
          applicantResidenceId,
        },
      });
    },
    {
      async onSuccess() {
        await refetch();
      },
    }
  );
}
