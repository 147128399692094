import { DollarField, Props as DollarFieldProps } from "@doorstead/components/fields/DollarField";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { getClampedValue } from "./getClampedValue";

export type Props = Omit<DollarFieldProps, "value" | "onChange" | "isValid"> & {
  defaultValue: number | null;
  min: number;
  max: number;
  getIsValid?: (value: number) => boolean;
  onSyncToServer: (value: number) => void;
  hasDollarSign?: boolean;
};

export function ApplicantDollarField({
  defaultValue,
  min,
  max,
  id,
  onSyncToServer,
  getIsValid,
  hasDollarSign = true,
  ...textProps
}: Props) {
  const [value, setValue] = useState<number>(defaultValue || 0);
  const debouncedSyncToServer = useCallback(_.debounce(onSyncToServer, 1500), [onSyncToServer]);
  useEffect(() => {
    return () => {
      debouncedSyncToServer.cancel();
    };
  }, [id]);

  return (
    <DollarField
      {...textProps}
      {...Object.assign(textProps, getIsValid ? { isValid: getIsValid(value) } : { isValid: true })}
      id={id}
      value={value}
      hasDollarSign={hasDollarSign}
      onChange={(value) => {
        setValue(getClampedValue(value, min, max));
        debouncedSyncToServer(value);
      }}
    />
  );
}
