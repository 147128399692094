import { HStack, VStack } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton, Switch } from "@doorstead/components";
import { Card, Props as CardProps } from "@doorstead/components/Card";
import { GrayText } from "@doorstead/components/GrayText";
import React from "react";
import { useApplicantAnalytics } from "../../../../../../hooks/applicants/useApplicantAnalytics";
import { ApplicantOnboardingStage } from "../../../../../../hooks/applicants/useFetchApplicationMe/Applicant";
import { ApplicantFileNodeType } from "../../../../../../hooks/applicants/useFetchApplicationMe/ApplicantFileNode";
import { useFetchedApplicant } from "../../../../../../hooks/applicants/useFetchedApplicant";
import { useUpdateApplicantField } from "../../../../../../hooks/applicants/useUpdateApplicantField";
import { useUpdateApplicantOnboardingStage } from "../../../../../../hooks/applicants/useUpdateApplicantOnboardingStage";
import { CardTitle } from "../../../../CardTitle";
import { UploadButton } from "../../UploadButton";
import { EditRow } from "./EditRow";

export type Props = Omit<CardProps, "children"> & {
  width: number | string;
  padding: number;
  onContinue: () => void;
};

export const ProofOfIncomeCard = React.forwardRef(({ padding, width, onContinue, ...props }: Props, ref) => {
  const applicantAnalytics = useApplicantAnalytics();
  const applicant = useFetchedApplicant();
  const { mutate } = useUpdateApplicantField();
  const { mutate: updateOnboarding, isLoading: isOnboardingLoading } = useUpdateApplicantOnboardingStage();

  return (
    <Card {...props} padding={padding} width={width} ref={ref}>
      <VStack spacing={4} width="100%" alignItems="flex-start">
        <CardTitle>Proof-of-income</CardTitle>
        <GrayText>
          <b>
            Tenants need to demonstrate 3x monthly rent in income. Guarantors need to demonstrate 5x monthly rent in
            income.
          </b>
          Or applicants should have savings or liquid assets of at least 80x monthly rent.
        </GrayText>
        <EditRow
          title="Salary income"
          rows={[
            {
              id: "new-job",
              title: "New job",
              component() {
                return (
                  <Switch
                    value={applicant.proofOfIncomeIsNewJob}
                    onChange={(proofOfIncomeIsNewJob) => {
                      mutate({
                        proofOfIncomeIsNewJob,
                      });
                    }}
                  />
                );
              },
            },
            {
              id: "upload-pay-stub",
              title: "",
              component() {
                return (
                  <UploadButton
                    title="Upload previous 2 pay stubs"
                    applicantFileType={ApplicantFileNodeType.PREV_TWO_PAY_STUBS}
                  />
                );
              },
            },
          ]}
        />
        <EditRow
          title="Self-Employed income"
          rows={[
            {
              id: "upload-previous-3-months-of-bank-statements",
              title: "",
              component() {
                return (
                  <UploadButton
                    title="Upload previous 3 months of bank statements"
                    applicantFileType={ApplicantFileNodeType.PREV_THREE_MONTHS_BANK_STATEMENT}
                  />
                );
              },
            },
            {
              id: "1099-tax-document",
              title: "",
              component() {
                return (
                  <UploadButton
                    title="1099 tax document"
                    applicantFileType={ApplicantFileNodeType.TEN_NINE_NINE_TAX_DOCUMENT}
                  />
                );
              },
            },
          ]}
        />
        <EditRow
          title="Other"
          rows={[
            {
              id: "upload-previous-x-months-of-bank-statements",
              title: "",
              component() {
                return (
                  <UploadButton
                    title="Upload previous x months of bank statements"
                    applicantFileType={ApplicantFileNodeType.PREV_X_MONTHS_BANK_STATEMENT}
                  />
                );
              },
            },
          ]}
        />
        <HStack w="100%" spacing={5}>
          <OutlineButton
            isLoading={isOnboardingLoading}
            onClick={() => {
              applicantAnalytics?.track("TP_PROOF_OF_INCOME_BACK_CLICKED");
              updateOnboarding(ApplicantOnboardingStage.DOCUMENTS_SECTION);
            }}
          >
            Back
          </OutlineButton>
          <PrimaryButton onClick={onContinue}>Continue</PrimaryButton>
        </HStack>
      </VStack>
    </Card>
  );
});
