import { Box, HStack, VStack } from "@chakra-ui/react";
import { useIsMobile } from "@doorstead/components";
import { OutlineButton } from "@doorstead/components";
import { moneyString } from "@doorstead/components";
// import { convertDateBetweenTimeZones } from "src/components/applicants/ApplicantLeaseNegotiationStartDatePage/convertDateBetweenTimeZones";
import { useNavigate } from "react-router-dom";
import { useFetchedApplicant } from "src/hooks/applicants/useFetchedApplicant";
import { useIsNegotiated } from "src/hooks/applicants/useIsNegotiated";
import { useLeaseNegotiationEnabled } from "src/hooks/applicants/useLeaseNegotiationEnabled";
import { getNegotiatedDateFormattedDateString } from "src/utils/getNegotiatedDateFormattedDateString";

export function NegotiationInfoCard() {
  const isMobile = useIsMobile();
  const {
    applicantId,
    applicationRequest: { negotiatedMoveInDate, negotiatedRent, negotiationLinkEndAt, negotiationLinkStartAt },
  } = useFetchedApplicant();
  const navigate = useNavigate();
  const isNegotiated = useIsNegotiated();
  const leaseNegotiationEnabled = useLeaseNegotiationEnabled();

  const currentDate = new Date();
  const negotiationLinkEndDate = negotiationLinkEndAt ? new Date(negotiationLinkEndAt) : null;
  const shouldShowNegotiationButton =
    negotiationLinkStartAt && negotiationLinkEndDate && negotiationLinkEndDate > currentDate;

  if (!leaseNegotiationEnabled || !isNegotiated) {
    return null;
  }
  if (isMobile) {
    return (
      <HStack
        w="100%"
        justifyContent="space-between"
        alignContent="space-between"
        bgColor="#F1EEFB"
        px={5}
        py={3}
        borderRadius={14}
      >
        <HStack spacing={5}>
          <VStack alignItems="flex-start" spacing={0}>
            <Box fontSize={isMobile ? 13 : 15}>Lease start date:</Box>
            <Box fontWeight="bold">{getNegotiatedDateFormattedDateString(negotiatedMoveInDate)}</Box>
          </VStack>

          <VStack alignItems="flex-start" spacing={0}>
            <Box fontSize={isMobile ? 13 : 15}>Monthly rent:</Box>
            <Box fontWeight="bold">{moneyString(negotiatedRent, false)}</Box>
          </VStack>
        </HStack>

        {shouldShowNegotiationButton && (
          <OutlineButton
            size="md"
            colorScheme="purple"
            onClick={() => {
              console.log("edit");
              navigate(`/lsd?applicantId=${applicantId}`);
            }}
          >
            edit
          </OutlineButton>
        )}
      </HStack>
    );
  } else {
    return (
      <HStack
        w="100%"
        justifyContent="space-between"
        alignContent="space-between"
        bgColor="#F1EEFB"
        px={5}
        py={3}
        borderRadius={14}
      >
        <VStack spacing={5} w="100%">
          <VStack w="100%" alignItems="flex-start" spacing={0}>
            <Box fontSize={isMobile ? 13 : 15}>Lease start date:</Box>
            <Box fontWeight="bold">{getNegotiatedDateFormattedDateString(negotiatedMoveInDate)}</Box>
          </VStack>

          <VStack w="100%" alignItems="flex-start" spacing={0}>
            <Box fontSize={isMobile ? 13 : 15}>Monthly rent:</Box>
            <Box fontWeight="bold">{moneyString(negotiatedRent, false)}</Box>
          </VStack>
        </VStack>

        {shouldShowNegotiationButton && (
          <OutlineButton
            size="md"
            colorScheme="purple"
            onClick={() => {
              console.log("edit");
              navigate(`/lsd?applicantId=${applicantId}`);
            }}
          >
            edit
          </OutlineButton>
        )}
      </HStack>
    );
  }
}
