import { useInspectionReport } from "../../hooks/rentReady/useInspectionReport";
import { useIsEvaluationCompleted } from "./useIsEvaluationCompleted";
import { useIsInspectionReportNew } from "./useIsInspectionReportNew";
import { useIsInspectionReportSubmitted } from "./useIsInspectionReportSubmitted";
import { useIsPropertyDetailsCompleted } from "./useIsPropertyDetailsCompleted";
import { useIsShouldShowEstimatesStep } from "./useIsShouldShowEstimatesStep";
import { View } from "./useView";

export function useInitialView(): View {
  const isShouldShowEstimatesStep = useIsShouldShowEstimatesStep();
  const isPropertyDetailsCompleted = useIsPropertyDetailsCompleted();
  const isEvaluationCompleted = useIsEvaluationCompleted();
  const isInspectionReportSubmitted = useIsInspectionReportSubmitted();
  const isInspectionReportNew = useIsInspectionReportNew();
  const { expirationDate, submittedTime } = useInspectionReport();

  if (expirationDate) {
    if (new Date() > new Date(expirationDate) && !submittedTime) {
      return View.ExpiredView;
    }
  }

  //new view
  if (isInspectionReportNew) {
    return View.StartView;
  }

  //first view
  if (!isPropertyDetailsCompleted) {
    return View.PropertyDetailView;
  }

  //second view
  if (!isEvaluationCompleted) {
    return View.AreaListView;
  }

  //third view
  if (isShouldShowEstimatesStep && !isInspectionReportSubmitted) {
    return View.EstimateListView;
  }

  //if all completed, show the first page
  return View.PropertyDetailView;
}
