import { Box, Flex, Stack, VStack } from "@chakra-ui/react";
import { CrossIcon, GrayText, useIsMobile } from "@doorstead/components";
import { Card } from "@doorstead/components/Card";
import { OutlineButton } from "@doorstead/components/buttons";
import produce from "immer";
import { useApplicantAnalytics } from "src/hooks/applicants/useApplicantAnalytics";
import { CardTitle } from "../../CardTitle";
import { Content } from "./Content";
import { Props } from "./typings";

export function NewApplicantsCardV2({
  values,
  title,
  subTitle,
  eventName,
  addLabel,
  numberLabel,
  onAdd,
  onChange,
  onRemove,
  duplicatedEmails,
  ...stackProps
}: Props) {
  const applicantAnalytics = useApplicantAnalytics();
  const isMobile = useIsMobile();
  const sortedValues = produce(values, (draft) => {
    return draft.sort((a, b) => {
      //use createdAt to sort the applicants
      if (a.createdAt && b.createdAt) {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
      }
      return 0;
    });
  });

  return (
    <Card {...stackProps} padding={7} width="100%">
      <VStack spacing={4} width="100%" alignItems="flex-start">
        <CardTitle>{title}</CardTitle>
        <GrayText width="100%">{subTitle}</GrayText>
        {sortedValues.map((value, index) => {
          const { id, removable = true } = value;
          return (
            <VStack width="100%" key={id} spacing={2} alignItems="flex-start">
              <Flex width="100%" alignItems="center" justifyContent="space-between">
                <Box color="gray.800" fontFamily="Inter" fontSize={20} fontWeight={600}>
                  {numberLabel} #{index + 1}
                </Box>
                {removable && (
                  <CrossIcon
                    size={15}
                    onClick={() => {
                      applicantAnalytics?.track(`TP_${eventName}_REMOVED`, { index });
                      onRemove(index);
                    }}
                  />
                )}
              </Flex>
              <Content
                isDuplicateEmail={duplicatedEmails.has(value.email)}
                value={value}
                onChange={(applicant) => onChange(applicant, index)}
              />
            </VStack>
          );
        })}
        <Stack direction="row" spacing={0} gap={4} flexWrap="wrap" w="100%">
          <OutlineButton
            isFullWidth={isMobile}
            onClick={() => {
              applicantAnalytics?.track(`TP_${eventName}_ADDED`);
              onAdd();
            }}
          >
            {addLabel}
          </OutlineButton>
        </Stack>
      </VStack>
    </Card>
  );
}
