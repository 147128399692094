import { ApplicationRequestStatus } from "../../../@types/ApplicationRequestStatus";
import { ApplicantNode } from "./Applicant";

export enum ContractType {
  GUARANTEE = "Guarantee",
  TRADITIONAL = "Traditional",
  TRANSFER = "Transfer",
  FLEX = "Flex",
  TPO = "TPO",
}

export type Contract = {
  type: ContractType;
};

export type Listing = {
  listingId: string;
  leaseNegotiationEnabled: boolean;
  price: number;
  moveInOptions: {
    startDate: string;
    endDate: string;
    price: number;
  }[];
  renewal: {
    contract: Contract;
  };
};

export type ApplicationRequestNode = {
  applicationRequestId: string;
  status: ApplicationRequestStatus;
  negotiatedRent: number;
  inviteCoTenantAndGuarantorAt: string | null;
  negotiatedMoveInDate: string;
  negotiationLinkStartAt: string | null;
  negotiationLinkEndAt: string | null;
  appSubmittedAt: string | null;
  primaryApplicant: ApplicantNode;
  property: {
    propertyId: string;
    isCoTenantsInviteV2Feature: boolean | null;
    isAdditionalOccupantsFeature: boolean | null;
    isFlex: boolean | null;
    isTpo: boolean | null;
    propertyType: string;
    bedrooms: number;
    bathrooms: number;
    fullAddress: string;
    addressLine1: string;
    addressLine2: string | null;
    zip: string;
    state: string;
    city: string;
    sqft: string;
  };
  listing: Listing;
};
