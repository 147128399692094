import { Box, Center, Divider, Flex, HStack, VStack } from "@chakra-ui/react";
import { Card } from "@doorstead/components/Card";
import { GrayText } from "@doorstead/components/GrayText";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { ExclamationIcon } from "@doorstead/components/icons/ExclamationIcon";
import { useApplicantPageView } from "../../../hooks/applicants/useApplicantPageView";
import { useFetchedApplicant } from "../../../hooks/applicants/useFetchedApplicant";
import { padding, width } from "../ApplicantOneSteps/config";
import { CardTitle } from "../CardTitle";
import { StartButton } from "./StartButton";

function Header() {
  return <CardTitle>Create account and apply</CardTitle>;
}

function Note() {
  const applicant = useFetchedApplicant();
  const applicantFullname = applicant?.doorsteadUser?.firstName || "";

  return (
    <>
      <GrayText>
        Welcome <b>{applicantFullname}</b>
        <br />
        <br />
        To help ensure the safety and security of your personal information, we’ll be asking you to create an account on
        the next screen.
      </GrayText>
    </>
  );
}

function LeftCard() {
  return (
    <VStack width="55%" paddingRight={10} spacing={3} alignItems="flex-start" justifyContent="space-between">
      <Header />
      <Note />
      <Box>
        <StartButton />
      </Box>
    </VStack>
  );
}

function TimeToComplete() {
  return (
    <VStack display="flex" flexDirection="column" width="100%" alignItems="flex-start" spacing={2}>
      <GrayText>Time to complete</GrayText>
      <Box>
        Between <b>20-30 min</b>
      </Box>
    </VStack>
  );
}

function PlatformFee() {
  return (
    <VStack display="flex" flexDirection="column" width="100%" alignItems="flex-start" spacing={2}>
      <HStack>
        <GrayText>Platform fee</GrayText>
        <ExclamationIcon />
      </HStack>
      <Box>
        <b>$45</b> per signer
      </Box>
    </VStack>
  );
}

function DocumentNeeded() {
  return (
    <VStack display="flex" flexDirection="column" width="100%" alignItems="flex-start" spacing={2}>
      <GrayText>Documents needed</GrayText>
      <Box sx={{ fontWeight: 600 }}>Valid ID card</Box>
    </VStack>
  );
}

function RightCard() {
  return (
    <VStack flex={1} justifyContent="center" alignItems="center">
      <VStack width={0.7} height="90%">
        <VStack flex={1} width="100%" alignItems="flex-start" spacing={1}>
          <TimeToComplete />
        </VStack>
        <VStack flex={1} width="100%" alignItems="flex-start" spacing={1}>
          <PlatformFee />
        </VStack>
        <VStack flex={1} width="100%" alignItems="flex-start" spacing={1}>
          <DocumentNeeded />
        </VStack>
      </VStack>
    </VStack>
  );
}

function MobileView() {
  return (
    <VStack width="100%" spacing={5}>
      <Header />
      <Note />
      <Divider />
      <VStack spacing={4} display="flex" justifyContent="flex-start" width="100%">
        <TimeToComplete />
        <PlatformFee />
        <DocumentNeeded />
      </VStack>
      <StartButton />
    </VStack>
  );
}

function DesktopView() {
  return (
    <Flex width="100%">
      <LeftCard />
      <Center>
        <Divider orientation="vertical" />
      </Center>
      <RightCard />
    </Flex>
  );
}

export function ApplicantLandingCard() {
  useApplicantPageView("TP_APPLICANT_LANDING_PAGE_VIEWED");
  const isMobile = useIsMobile();

  return (
    <Card padding={padding} width={width} maxWidth="800px">
      {isMobile ? <MobileView /> : <DesktopView />}
    </Card>
  );
}
