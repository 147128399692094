import { Box, HStack, StackProps, VStack } from "@chakra-ui/react";
import { OutlineButton } from "@doorstead/components";
import { Card } from "@doorstead/components/Card";
import { GrayText } from "@doorstead/components/GrayText";
import { PrimaryButton } from "@doorstead/components/buttons";
import { YesNoSelectField } from "@doorstead/components/fields";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import React, { useEffect } from "react";
import { useApplicantAnalytics } from "../../../../../../hooks/applicants/useApplicantAnalytics";
import { ApplicantOnboardingStage } from "../../../../../../hooks/applicants/useFetchApplicationMe/Applicant";
import { useFetchedApplicant } from "../../../../../../hooks/applicants/useFetchedApplicant";
import { useIsApplicantEditable } from "../../../../../../hooks/applicants/useIsApplicantEditable";
import { useUpdateApplicantOnboardingStage } from "../../../../../../hooks/applicants/useUpdateApplicantOnboardingStage";
import { useUpdateDoorsteadUserField } from "../../../../../../hooks/applicants/useUpdateDoorsteadUserField";
import { ApplicantDateField } from "../../../../ApplicantDateField";
import { CardTitle } from "../../../../CardTitle";
import { Labels } from "../../../labels";
import { getBirthdayFromYear } from "./getBirthdayFromYear";
import { getBirthdayToYear } from "./getBirthdayToYear";

export type Props = StackProps & {
  padding: number;
  width: number | string;
  maxWidth: number | string;
  id: string;
  onContinue: () => void;
};

export const BackgroundCheckCard = React.forwardRef(({ padding, width, maxWidth, id, onContinue }: Props, ref) => {
  const { doorsteadUser } = useFetchedApplicant();
  const applicantAnalytics = useApplicantAnalytics();
  const { mutate: updateOnboarding, isLoading: isOnboardingLoading } = useUpdateApplicantOnboardingStage();
  const disabled = !useIsApplicantEditable();
  const isMobile = useIsMobile();
  const { mutate: updateDoorsteadUserField } = useUpdateDoorsteadUserField();
  const { isUsResident, dayOfBirthTemp, hasBankruptcy, isEvicted, hasGuilty } = doorsteadUser;

  useEffect(() => {
    if (isUsResident === null) {
      updateDoorsteadUserField({ isUsResident: true });
    }
  }, [isUsResident]);

  return (
    <Card id={id} padding={padding} width={width} maxWidth={maxWidth} ref={ref}>
      <VStack width="100%" alignItems="flex-start">
        <CardTitle>Background check</CardTitle>
        <GrayText>The below information will be used to run a background check.</GrayText>
        {!isUsResident && (
          // For US residents, we collect the date of birth in Persona
          <Box w="100%">
            <ApplicantDateField
              id="birthday"
              label={Labels.Birthday}
              disabled={disabled}
              value={dayOfBirthTemp}
              fromYear={getBirthdayFromYear()}
              toYear={getBirthdayToYear()}
              onChange={(dayOfBirthTemp) => {
                updateDoorsteadUserField({
                  dayOfBirthTemp,
                });
              }}
            />
          </Box>
        )}

        <YesNoSelectField
          id="hasBankruptcy"
          label="Have you ever filed for bankruptcy, been foreclosed on, or been a defendant in a civil suit?"
          value={hasBankruptcy}
          disabled={disabled}
          onChange={(hasBankruptcy) => {
            updateDoorsteadUserField({
              hasBankruptcy,
            });
          }}
        />

        <YesNoSelectField
          id="isEvicted"
          label="Have you ever been evicted from a tenancy or left owing money?"
          disabled={disabled}
          value={isEvicted}
          onChange={(isEvicted) => {
            updateDoorsteadUserField({
              isEvicted,
            });
          }}
        />

        <YesNoSelectField
          id="hasGuilty"
          label="Have you ever been convicted or pleaded guilty or no contest to any criminal offenses?"
          value={hasGuilty}
          disabled={disabled}
          onChange={(hasGuilty) => {
            updateDoorsteadUserField({
              hasGuilty,
            });
          }}
        />

        <HStack w="100%" spacing={5}>
          <OutlineButton
            isLoading={isOnboardingLoading}
            onClick={() => {
              applicantAnalytics?.track("TP_BACKGROUND_CHECK_BACK_CLICKED");
              updateOnboarding(ApplicantOnboardingStage.YOUR_INFORMATION_SECTION);
            }}
          >
            Back
          </OutlineButton>
          <PrimaryButton isFullWidth={isMobile} onClick={onContinue}>
            Continue
          </PrimaryButton>
        </HStack>
      </VStack>
    </Card>
  );
});
