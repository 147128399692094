export function isValidSSN(ssn: string): boolean {
  if (ssn.length !== 9) {
    return false;
  }

  if (!/^\d{9}$/.test(ssn)) {
    return false;
  }

  // Extract area, group, and serial numbers
  const area = ssn.substring(0, 3);
  const group = ssn.substring(3, 5);
  const serial = ssn.substring(5, 9);

  // Area number cannot be 000, 666, or between 900-999
  if (area === "000" || area === "666" || (area >= "900" && area <= "999")) {
    return false;
  }

  // Group number cannot be 00
  if (group === "00") {
    return false;
  }

  // Serial number cannot be 0000
  if (serial === "0000") {
    return false;
  }

  return true;
}
