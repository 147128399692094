import { VStack } from "@chakra-ui/react";
import { useApplicantPageView } from "../../../../../hooks/applicants/useApplicantPageView";
import { ApplicantOnboardingStage } from "../../../../../hooks/applicants/useFetchApplicationMe/Applicant";
import { useUpdateApplicantOnboardingStage } from "../../../../../hooks/applicants/useUpdateApplicantOnboardingStage";
import { useScrollIntoView } from "../../../../../hooks/useScrollIntoView";
import { maxWidth, padding, width } from "../../config";
import { BackgroundCheckCard } from "./BackgroundCheckCard";
import { PersonaPhotoIdCard } from "./PersonaPhotoIdCard";

export function DocumentSection() {
  const applicantAnalytics = useApplicantPageView("TP_DOCUMENT_SECTION_VIEWED");
  const { mutate } = useUpdateApplicantOnboardingStage();
  const scrollIntoView = useScrollIntoView();

  return (
    <VStack id="/1" spacing={8}>
      <PersonaPhotoIdCard
        id="/1/children/0"
        padding={padding}
        width={width}
        onContinue={async () => {
          applicantAnalytics?.track("TP_PHOTO_ID_CARD_CONTINUED");
          scrollIntoView("/1/children/1");
        }}
      />
      <BackgroundCheckCard
        id="/1/children/1"
        padding={padding}
        width={width}
        maxWidth={maxWidth}
        onContinue={async () => {
          applicantAnalytics?.track("TP_BACKGROUND_CHECK_CARD_CONTINUED");
          await mutate(ApplicantOnboardingStage.EMPLOYMENT_VERIFICATIONS_SECTION);
        }}
      />
    </VStack>
  );
}
